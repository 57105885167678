.img {
    display: block;
    max-width: 100%;
    height: auto;
}

.photo-twitter {
    height: 50px;
    width: 50px;
}

.photo-github {
    height: 30px;
    width: 30px;
    margin-right: 8px;
}

.photo-linkedin {
    height: 30px;
    width: 30px;
    margin-right: 8px;
}

.photo-mail {
    height: 28px;
    width: 35px;
    margin-right: 8px;
    margin-left: 8px;
}

.footer-overall {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}
