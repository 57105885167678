.section_container {
    display: flex;
    flex-direction: row;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-bottom: 10%;
}

.section_image {
    text-align: center;
}

.section_image img {
    width: 60%;
    height: auto;
}

.section_image {
    width: 50%;
}

.section_text {
    text-align: center;
    padding-right: 5%;
    padding-left: 5%;
    width: 50%;
    padding-top: 2.5%;
}

@media screen and (max-width: 550px) {
    .section_container {
        display: flex;
        flex-direction: column;
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .section_image {
        width: 100%;
        height: auto;
        float: none;
    }

    .section_text {
        text-align: center;
        padding-top: 5%;
        align-items: center;
        justify-content: center;
        margin-left: 25%;
    }
}