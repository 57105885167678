.grid {
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.grid > div {
    flex-basis: calc(50% - 40px);
}

.box {
    margin: 20px;
}

.card-image {
    height: 100%;
}

.experiences-cards {
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

@media screen and (max-width: 700px) {
    .section_padding {
      padding: 4rem;
    }
  
    .section_margin {
      margin: 4rem;
    }
  }
  
  /** mobile devices */
  
@media screen and (max-width: 550px) {
    .section_padding {
      padding: 4rem 2rem;
    }
  
    .section_margin {
      margin: 4rem 2rem;
    }  
  }