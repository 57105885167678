.right {
    position: absolute;
    right: 0px;
    width: 300px;
    border: 3px solid #73AD21;
    padding: 10px;
    margin-right: 300px;
}

.centered {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 75%;
    height: auto;
}