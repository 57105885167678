.nav-components {
    margin-right: 30px;
    /* padding-right: 30px; */
}

#navbar-custom-padding {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
}

.img {
    height: 10px;
    width: 10px;
    padding: 0;
    margin: 0;
}

.navbar-comp {
    margin: 0;
    padding: 0;
}