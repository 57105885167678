.img {
    width: 75%;
    height: auto;
}

.logo-image {
    width: 40%;
    height: 50%;
}

.rows {
    margin-top: 50px;
    margin-bottom: 50px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}