.img {
    width: 75%;
    height: auto;
}

.top-text {
    margin-bottom: 50px;
    padding-top: 0;
}

.top {
    padding-top: 0;
    margin-top: 40px;
}

.logo {
    height: 100px;
    width: 300px;
}

.bottom-row {
    margin-bottom: 100px;
}